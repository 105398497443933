import { showToastV2 } from 'blocks/toast/toast.ts';
import { fetchPlaceholders } from 'scripts/aem.js';
import { LANGUAGES } from 'shared/constants.ts';
import { getLanguageFromPath } from 'utils/languageUtils.ts';

import './language-fallback-toast.css';

const REFERRER_LANG_PARAM = 'referrer-lang';

export async function showLanguageFallbackToast() {
	const searchParams = new URLSearchParams(window.location.search);
	const referrerLangParam = searchParams.get(REFERRER_LANG_PARAM);
	if (!referrerLangParam) return;

	searchParams.delete(REFERRER_LANG_PARAM);
	const url = new URL(window.location.href);
	url.search = searchParams.toString();
	window.history.replaceState({}, '', url.toString());

	const referrerLang = LANGUAGES.find(
		(l) => l === referrerLangParam?.toLowerCase(),
	);
	if (!referrerLang) return;

	const currentLang = getLanguageFromPath();
	if (currentLang === referrerLang) return;

	const placeholders = await fetchPlaceholders(referrerLang);

	const { message, actionLabel, actionUrl } = {
		message:
			placeholders.languageFallbackToast ||
			'Not all content is available in all languages. If you need support, please',
		actionLabel: placeholders.languageFallbackToastActionLabel || 'contact us.',
		actionUrl:
			placeholders.languageFallbackToastActionUrl ||
			'https://evidentscientific.com/en/contact-us',
	};

	showToastV2(document.body, 'announcement', message, {
		actionLabel,
		actionUrl,
		customClass: 'toast__language-fallback',
		timeoutMs: 30000,
	});
}
